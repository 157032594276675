import * as React from "react";
import DefaultLayout from "./default";
import { Helmet } from "react-helmet";
import Container from "../components/Container";
import styled from "styled-components";
import { dimensions } from "../styles/variables";
import CarDetail from "../components/CarDetail";

export const GreyContainer = styled(Container)`
  background-color: #e4e4e4;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-around;
`;

export const BlueBar = styled.div`
  background-color: #03498a;
  color: white;
  font-size: ${dimensions.fontSize.large}px;
  padding: 32px 0;
`;
export const TitleContainer = styled.div`
  padding: 0 160px;
`;

interface ModelProps {
  pageContext: {
    model: any;
    allIncl?: any;
  };
}

const Model: React.FC<ModelProps> = (props) => {
  const {
    pageContext: { model, allIncl },
  } = props;
  return (
    <DefaultLayout>
      <BlueBar>
        <Container>
          <TitleContainer>Fahrzeugbeschreibung</TitleContainer>
        </Container>
      </BlueBar>
      <GreyContainer>
        <CarDetail allIncl={allIncl} model={model} />
      </GreyContainer>
    </DefaultLayout>
  );
};

export default Model;
